'use client';

import React, { HTMLProps, useEffect, useRef } from 'react';
import { usePrefersColorScheme } from '../../ui/hooks/usePrefersColorScheme';

class NumberElementConfig {
  canvas: HTMLCanvasElement;
  values: string[];
  x: number[];
  y: number[];
  size: number[];
  speed: number[];

  constructor(
    canvas: HTMLCanvasElement,
    values: string[],
    xMinMax: number[],
    yMinMax: number[],
    sizeMinMax: number[],
    speedMinMax: number[]
  ) {
    this.canvas = canvas;
    this.values = values;
    this.x = xMinMax;
    this.y = yMinMax;
    this.size = sizeMinMax;
    this.speed = speedMinMax;
  }
}

class NumberElement {
  config: NumberElementConfig;
  canvas: HTMLCanvasElement;
  value: number = 0;
  size: number = 0;
  speed: number = 0;
  x: number = 0;
  y: number = 0;
  viewportOffset: number = 0;

  constructor(numberElementConfig: NumberElementConfig) {
    this.config = numberElementConfig;
    this.canvas = this.config.canvas;
    this.spawn();
  }

  random(property: string | string[] | number[]): string | number {
    return property[Math.floor(Math.random() * property.length)];
  }

  randomValue(property: keyof NumberElementConfig) {
    const p = this.config[property] as number[];
    return Math.random() * (p[1] - p[0]) + p[0];
  }

  spawn() {
    this.value = this.random(this.config.values) as number;
    this.size = this.randomValue('size');
    this.speed = this.randomValue('speed');
    this.x = this.randomValue('x');
    this.y = this.randomValue('y');

    this.viewportOffset = this.config.size[1] + 20;

    if (Math.abs(this.speed) < 1) {
      this.speed += this.speed < 0 ? -1 : 1;
    }

    this.setSpawnPosition();
  }

  respawn() {
    this.spawn();
  }

  setSpawnPosition() {
    const key = this.random(['x', 'y']);

    if (this.speed < 0) {
      const value = `${key}` === 'x' ? this.canvas.width : this.canvas.height;
      this[key as 'x' | 'y'] = value + this.size;
    } else {
      this[key as 'x' | 'y'] = 0 - this.size;
    }
  }

  isInViewport() {
    if (
      this.x < 0 - this.viewportOffset ||
      this.y < 0 - this.viewportOffset ||
      this.x > this.canvas.width + this.viewportOffset ||
      this.y > this.canvas.height + this.viewportOffset
    ) {
      return false;
    }
    return true;
  }

  move() {
    this.x += this.speed;
    this.y += this.speed;
  }
}

class NumberElementRenderer {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D | null;
  numberElements: NumberElement[];
  colorScheme: 'light' | 'dark' = 'light';

  constructor(canvas: HTMLCanvasElement, count = 100, spawnTimeout = 20) {
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d');
    this.numberElements = [];

    const generator = () => {
      if (count !== this.numberElements.length) {
        this.numberElements.push(this.generateNumberElement());
        setTimeout(generator, spawnTimeout);
      }
    };
    generator();
  }

  get() {
    return this.numberElements;
  }

  moveAll() {
    this.numberElements.forEach((element) => {
      element.move();
      if (!element.isInViewport()) {
        element.respawn();
      }
    });
  }

  draw() {
    const ctx = this.ctx;

    if (!ctx) {
      return;
    }

    this.canvas.width = this.canvas.offsetWidth / 2;
    this.canvas.height = this.canvas.offsetHeight / 2;

    ctx.beginPath();
    ctx.fillStyle =
      this.colorScheme === 'light'
        ? 'rgba(0, 0, 0, 0.1)'
        : 'rgba(255, 255, 255, 0.2)';
    this.numberElements.forEach((numberElement) => {
      ctx.font = `${numberElement.size}px sans-serif`;
      ctx.textBaseline = 'bottom';
      ctx.textAlign = 'left';
      ctx.fillText(`${numberElement.value}`, numberElement.x, numberElement.y);
    });

    this.moveAll();

    requestAnimationFrame(this.draw.bind(this));
  }

  generateNumberElement() {
    return new NumberElement(
      new NumberElementConfig(
        this.canvas,
        ['0', '1'], // values
        [0, this.canvas.offsetWidth], // x
        [0, this.canvas.offsetHeight], // y
        [4, 16], // size
        [-12, 12] // speed
      )
    );
  }
}

export const BinaryCanvas: React.FC<HTMLProps<HTMLCanvasElement>> = (props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const numberElementRendererRef = useRef<NumberElementRenderer>(undefined);

  const { colorScheme } = usePrefersColorScheme();

  useEffect(() => {
    if (numberElementRendererRef.current) {
      numberElementRendererRef.current.colorScheme = colorScheme;
    }
  }, [colorScheme]);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (!canvas) return;

    const numberElementRenderer = new NumberElementRenderer(canvas);
    numberElementRendererRef.current = numberElementRenderer;
    numberElementRendererRef.current.colorScheme = colorScheme;
    numberElementRendererRef.current.draw();
  }, [canvasRef.current]);

  return <canvas ref={canvasRef} {...props} />;
};
